// App.js - WEB
import React, { Component } from "react";
import AppController from "./AppController.web";
import HomeScreen from "../../../components/src/HomeScreen";
import InfoPage from "../../info-page/src/InfoPageBlock";
import AlertBlock from "../../alert/src/AlertBlock.web";
import WebRoutes from "./WebRoutes";
import { withRouter } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assets/fontastic/styles.css";
const routeMap = {
  Home: {
    component: HomeScreen,
    path: "/",
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: "/InfoPage",
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true,
  },
};
class App extends Component {
  // async componentDidMount() {
  //   window.scrollTo(0, 0);
  //   this.setState({ loader: true });
  // }

  render() {
    return (
      // <div className='h-100'>
        <WebRoutes />
      // </div>
    );
  }
}

export default App;
