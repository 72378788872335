import React, { Component } from "react";
import { Route, RouteComponentProps, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";

export default class ProtectedRoute extends Component<RouteComponentProps> {
  render() {
    const userData = JSON.parse(window.localStorage.getItem("adminuser") ?? "{}");
    const token = window.localStorage.getItem("admintoken");
    
    const loggedIn = Boolean(userData?.data?.attributes?.email);
    const expired = token
      ? // @ts-ignore
        new Date().getTime() > (jwt.decode(token)?.exp || 0) * 1000
      : true;

    if (expired || !loggedIn) {
      window.localStorage.removeItem("admintoken");
      window.localStorage.removeItem("adminuser");
      return <Redirect to="/login" />;
    }

    return <Route {...this.props} />;
  }
}
